import * as React from "react";

// markup
const NotFoundPage = () => {
  return (
    <main>
      <title>Not found</title>
      <div>404</div>
    </main>
  );
};

export default NotFoundPage;
